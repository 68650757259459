import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const PolicyParagraph = memo(function PolicyParagraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 10.875rem auto 6.25rem;
  padding: 0 5.833vw;

  @media (max-width: 1023px) {
    margin: 8.75rem auto 5.625rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.875rem;
  margin-top: 1.875rem;

  a {
    text-decoration: underline;
  }
  p {
    margin: inherit;
  }
  ul {
    list-style: disc;
    margin: inherit;
    margin-inline-start: 1rem;
    padding: inherit;
  }
`
